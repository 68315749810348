
















import "reflect-metadata";

import { Component, Prop, Vue } from "vue-property-decorator";

import { Api } from "@/constants";
import {
  ADD_EVENT_CLICKED,
  FILTER_APPLIED,
  GOING_CLICKED,
} from "@/modules/event/event-handlers";
import EventList from "@/modules/event/list/components/EventList.vue";
import { choicesStore, eventsStore } from "@/store";

@Component({ components: { EventList } })
export default class EventListContainer extends Vue {
  @Prop({ required: true }) readonly cityId!: string;
  @Prop({ required: true }) readonly styleId!: string;
  @Prop({ required: true }) readonly hasFilter!: boolean;

  get handlerGoingClicked() {
    return GOING_CLICKED;
  }

  get handlerFilterApplied() {
    return FILTER_APPLIED;
  }

  get handlerAddEventClicked() {
    return ADD_EVENT_CLICKED;
  }

  get apiLoadingEvents() {
    return eventsStore.loading(Api.EventsLoadEvents);
  }

  get getApiLoadingEventGoing() {
    return (eventId: string) => {
      return eventsStore.loading(`${Api.EventsUpdateEventGoing}--${eventId}`);
    };
  }

  get events() {
    return eventsStore.events;
  }

  get choicesCities() {
    return choicesStore.choices.cities;
  }

  get choicesStyles() {
    return choicesStore.choices.styles;
  }
}
