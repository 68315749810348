<template>
  <event-list-container :city-id="cityId" :style-id="styleId" :has-filter="true" />
</template>

<script>
import { getTodayISO, getWeekendEndISO, getWeekendStartISO } from "@/datetimes";
import EventListContainer from "@/modules/event/list/EventListContainer";
import { choicesStore, eventsStore } from "@/store";

const loadEventsAndChoices = (to, from, next) => {
  let datesQuery;
  const tab = ~["all", "today", "weekend"].indexOf(to.query.tab) ? to.query.tab : "all";

  if (tab === "all") {
    datesQuery = {
      endDateGte: getTodayISO(),
      endDateLte: undefined,
      startDateGte: undefined,
      startDateLte: undefined,
    };
  } else if (tab === "today") {
    datesQuery = {
      endDateGte: getTodayISO(),
      endDateLte: undefined,
      startDateGte: undefined,
      startDateLte: getTodayISO(),
    };
  } else if (tab === "weekend") {
    datesQuery = {
      endDateGte: getWeekendStartISO(),
      endDateLte: undefined,
      startDateGte: undefined,
      startDateLte: getWeekendEndISO(),
    };
  }

  return Promise.all([
    eventsStore.loadEvents({
      cityId: to.query.cityId,
      styleId: to.query.styleId,
      endDateGte: to.query.endDateGte || getTodayISO(),
      sortBy: to.query.sortBy,
      sortByOrder: to.query.sortByOrder,
      limit: to.query.limit,
      offset: to.query.offset,
      ...datesQuery,
    }),
    choicesStore.loadCities(),
    choicesStore.loadStyles(),
  ]).then(() => next());
};

export default {
  name: "EventListView",
  metaInfo() {
    return {
      title: this.$t("viewsMeta.eventList.title"),
      meta: [
        {
          name: "description",
          content: this.$t("viewsMeta.eventList.description"),
        },
      ],
    };
  },
  components: { EventListContainer },
  props: {
    cityId: String,
    styleId: String,
  },
  beforeRouteEnter: loadEventsAndChoices,
  beforeRouteUpdate: loadEventsAndChoices,
};
</script>
