






















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { Choice } from "@/api/structs";
import AppControlAutocomplete from "@/components/AppControlAutocomplete.vue";
import {
  ADD_EVENT_CLICKED,
  FILTER_APPLIED,
  FilterAppliedPayload,
} from "@/modules/event/event-handlers";

@Component<EventListFilter>({
  components: {
    AppControlAutocomplete,
  },
  data() {
    return {
      cityIdModel: this.cityId,
      styleIdModel: this.styleId,
    };
  },
})
export default class EventListFilter extends Vue {
  private $mq!: string;
  private cityIdModel!: string | null;
  private styleIdModel!: string | null;
  private activeTab = "";

  @Prop({ required: true }) readonly choicesCities!: Choice[];
  @Prop({ required: true }) readonly choicesStyles!: Choice[];
  @Prop({ required: true }) readonly cityId!: string | null;
  @Prop({ required: true }) readonly styleId!: string | null;

  created() {
    // @ts-ignore
    this.activeTab = ["all", "today", "weekend"].includes(this.$route.query.tab)
      ? this.$route.query.tab
      : "all";
  }

  @Watch("activeTab")
  async onActiveTabChanged(val: string) {
    await this.$router.replace({
      query: {
        ...this.$router.currentRoute.query,
        tab: val,
      },
    });
  }

  @Watch("cityId")
  cityIdChanged(val: string | null) {
    this.cityIdModel = val;
  }

  @Watch("styleId")
  styleIdChanged(val: string | null) {
    this.styleIdModel = val;
  }

  emitFilterApplied() {
    this.$emit(FILTER_APPLIED.name, {
      cityId: this.cityIdModel,
      styleId: this.styleIdModel,
    } as FilterAppliedPayload);
  }

  emitAddEventClicked() {
    this.$emit(ADD_EVENT_CLICKED.name);
  }

  get size() {
    return this.$mq === "tablet" ? "is-small" : "";
  }

  get userCreateEventsEnabled() {
    return true;
  }
}
