



























import { Component, Prop, Vue } from "vue-property-decorator";

import { ApiLoading, Choice, Event } from "@/api/structs";
import AppFailure from "@/components/AppFailure.vue";
import EventListFilter from "@/modules/event/list/components/EventListFilter.vue";
import EventListItem from "@/modules/event/list/components/EventListItem.vue";

@Component({
  components: {
    AppFailure,
    EventListFilter,
    EventListItem,
  },
})
export default class EventList extends Vue {
  @Prop({ required: true }) readonly events!: Event[];
  @Prop({ required: true }) readonly choicesCities!: Choice[];
  @Prop({ required: true }) readonly choicesStyles!: Choice[];
  @Prop({ required: true }) readonly cityId!: string;
  @Prop({ required: true }) readonly styleId!: string;
  @Prop({ required: true }) readonly hasFilter!: boolean;
  @Prop({ required: true }) readonly apiLoadingEvents!: ApiLoading;
  @Prop({ required: true }) readonly getApiLoadingEventGoing!: (
    eventId: string,
  ) => ApiLoading;
}
