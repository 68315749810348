<template>
  <b-autocomplete
    v-bind="{ ...$props, ...$attrs }"
    v-model="valueName"
    :class="{ 'is-fixed': fixed, 'is-size-7': size === 'is-small' }"
    :data="filteredData"
    :field="nameKey"
    :group-field="groupNameKey"
    @typing="t => $emit('typing', t)"
    @select="
      option => $emit('input', emitIdOnly ? option && option[this.idKey] : option || '')
    "
    @select-header="$emit('select-header')"
    :selectable-header="true"
  >
    <template #empty v-if="emptyMessage || hasEmptySlot">
      <slot name="empty">
        {{ emptyMessage }}
      </slot>
    </template>
    <template #header>
      <slot name="header" v-if="showHeaderSlot" />
    </template>
    <template v-if="hasGroup" #group="slotProps">
      <!-- For some reason, `buefy` converts `slotProps.group` to string, so null is a
           string here. -->
      <hr v-if="slotProps.group === ' Россия'" class="is-marginless mb-3" />
      <strong v-if="slotProps.group !== 'null'">{{ slotProps.group }}</strong>
    </template>
  </b-autocomplete>
</template>

<script>
export default {
  name: "AppControlAutocomplete",
  props: {
    // Same props as in the `BAutocomplete`.
    data: Array,
    size: String,
    // `v-model`.
    value: {
      validator: () => true,
    },
    // Custom props for this component.
    fixed: Boolean,
    idKey: String,
    nameKey: String,
    headerOnEmpty: {
      type: Boolean,
      default: false,
    },
    // Weird logic specifically for `EventListFilter`. Remove after #164 is
    // done.
    emitIdOnly: {
      type: Boolean,
      default: false,
    },
    groupNameKey: {
      type: String,
      default: "",
    },
    emptyMessage: String,
  },
  data: function () {
    return {
      valueName: null,
    };
  },
  mounted: function () {
    this.valueName = this.findValueName(this.value);
  },
  watch: {
    value: function (val) {
      this.valueName = this.findValueName(val);
    },
  },
  computed: {
    hasGroup() {
      return this.groupNameKey !== null;
    },
    hasEmptySlot() {
      return !!this.$slots.empty;
    },
    showHeaderSlot() {
      if (!this.$slots.header) {
        return false;
      }
      return this.headerOnEmpty ? this.filteredData.length === 0 : true;
    },
    filteredData() {
      return this.data.filter(option => {
        return (
          option[this.nameKey]
            .toString()
            .toLowerCase()
            .indexOf((this.valueName || "").toLowerCase()) >= 0
        );
      });
    },
  },
  methods: {
    findValueName: function (value) {
      let valueObject;

      if (typeof value === "string") {
        valueObject = this.data.find(option => option[this.idKey] === value);
      } else {
        valueObject = value;
      }

      return valueObject && valueObject[this.nameKey];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/utilities";

.is-fixed {
  min-width: 200px;
  max-width: 200px;

  @include mobile {
    min-width: 115px;
    max-width: 115px;
  }
}

// WTF: For some reason, there's `.control` inside a `.control` in the buefy's
// and they behave poorly together --- additional `margin-right: -1` makes an
// element jumpy on hover. This construction removes `margin-right` from the
// nested control.
.control::v-deep {
  .control {
    margin-right: 0 !important;
  }
}

// WTF: There's no way to control the text size of a dropdown item other than
// another `v-deep` directive.
.is-size-7::v-deep {
  .dropdown-item {
    font-size: inherit;
    overflow: initial;
  }
}
</style>
